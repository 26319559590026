import React, { forwardRef, SyntheticEvent } from 'react'
import Loader from 'components/common/Loader'

export type ButtonProps = {
  as?: string
  style?: React.CSSProperties
  className?: string
  href?: string | null
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'small'
    | 'primarySmall'
    | 'secondarySmall'
    | 'outlineSmall'
    | 'whatsapp'
    | 'custom'
  target?: '_blank'
  rel?: 'noopener' | 'noreferrer' | 'noreferrer noopener'
  disabled?: boolean
  loading?: boolean
  children: any
  type?: 'button' | 'submit' | null
  onClick?: () => void
  onKeyPress?: (e: React.KeyboardEvent) => void
}

const Button = forwardRef(
  (
    {
      as = 'button',
      className = '',
      style,
      href = null,
      children,
      disabled,
      target,
      rel,
      loading,
      variant = 'primary',
      type = null,
      onClick,
      onKeyPress,
    }: ButtonProps,
    ref
  ) => {
    const Element: any = as

    return (
      children && (
        <Element
          href={href}
          style={style}
          ref={ref}
          onClick={onClick}
          disabled={disabled || loading}
          target={(as === 'a' && target) || null}
          rel={(as === 'a' && rel) || null}
          type={as === 'button' || as === 'input' ? type : null}
          className={`
          relative
          text-center
          inline-flex
          gap-2
          items-center
          justify-center
          align-middle
          font-medium
          transition
          focus:outline-none
          focus:ring
          focus:ring-opacity-40
          disabled:pointer-events-none
          disabled:text-opacity-50
          disabled:shadow-none
          disabled:cursor-not-allowed
          text-sm
          sm:text-base
          rounded-md
          ${
            variant !== 'whatsapp'
              ? `
            disabled:bg-violet-5
            disabled:text-violet-100`
              : `
              disabled:opacity-50
              bg-green-100
              text-white
              shadow-primary
              h:hover:bg-green-80
              h:hover:bg-opacity-90
              h:hover:shadow-primary-lg
              focus:ring-green-100
              focus:ring-opacity-60
              px-7.5
              sm:px-10
              h-14
              sm:h-16.5
            `
          }
          ${
            variant === 'primary'
              ? `
            bg-violet-100
            text-white
            shadow-primary
            h:hover:bg-violet-80
            h:hover:shadow-primary-lg
            focus:ring-violet-100
            px-7.5
            sm:px-10
            h-14
            sm:h-16.5
          `
              : ''
          }
          ${
            variant === 'secondary'
              ? `
            bg-violet-10
            h:hover:shadow-secondary
            focus:ring-violet-20
            text-violet-100
            px-7.5
            sm:px-10
            h-14
            sm:h-16.5
          `
              : ''
          }
          ${
            variant === 'outline'
              ? `
              bg-transparent
              h:hover:shadow-secondary
              focus:ring-violet-20
              text-violet-100
              border-2 border-violet-10
              px-7.5
              sm:px-10
              h-14
              sm:h-16.5
          `
              : ''
          }
          ${
            variant === 'small'
              ? `
            bg-violet-10
            h:hover:shadow-secondary
            focus:ring-violet-20
            text-violet-100
            px-5.5
            sm:px-8
            h-12
            sm:h-12.5
          `
              : ''
          }
          ${
            variant === 'primarySmall'
              ? `
            bg-violet-100
            text-white
            shadow-primary
            h:hover:bg-violet-80
            h:hover:shadow-primary-lg
            focus:ring-violet-100
            px-5.5
            sm:px-8
            h-12
            sm:h-12.5
          `
              : ''
          }
          ${
            variant === 'secondarySmall'
              ? `
            bg-violet-10
            h:hover:shadow-secondary
            focus:ring-violet-20
            text-violet-100
            px-5.5
            sm:px-8
            h-12
            sm:h-12.5
          `
              : ''
          }

          ${
            variant === 'outlineSmall'
              ? `
            bg-transparent
            border
            border-opacity-85
            border-black
            text-black
            text-opacity-85
            h:hover:bg-white
            h:hover:shadow-secondary
            focus:ring-violet-15
            px-5.5
            sm:px-8
            h-12
            sm:h-12.5
          `
              : ''
          }
          ${
            variant === 'custom'
              ? `
            text-white
            shadow-primary
            h:hover:shadow-primary-lg
            px-7.5
            sm:px-10
            h-12
            sm:h-12.5
          `
              : ''
          }
          ${className}
          `}
        >
          {loading && (
            <Loader
              size="sm"
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
            />
          )}
          <span
            className={`inline-flex gap-2 transition-opacity items-center ${
              loading ? 'opacity-0' : ''
            }`}
          >
            {children}
          </span>
        </Element>
      )
    )
  }
)

Button.displayName = 'button'
export default Button
