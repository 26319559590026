import React from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import 'react-multi-carousel/lib/styles.css'

import Layout from 'components/common/Layout'
import Button from 'components/common/Button'

type Locale = {
  locale: string
}

export async function getStaticProps({ locale }: Locale) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

const BusinessPage = () => {
  return (
    <Layout title="index.title" description="index.metaDescription">
      <div className="flex flex-col items-center text-center mt-50 w-full p-4 max-w-3xl mx-auto gap-10">
        <h1 className="text-5xl font-bold">
          Das Pilotprojekt Goodee wurde per 26.6.2024 eingestellt.
        </h1>

        <div>
          <h2 className="font-bold">
            Für Besitzer eines All-in-One Gutscheins:
          </h2>
          <p>
            Falls du einen gültigen All-in-One Gutschein besitzt, überweisen wir
            dir den Restbetrag innerhalb von 14 Tagen auf dein Konto.
          </p>

          <Button as="a" href={'/aio-redeem'} className="mt-5">
            Rückzahlung beantragen
          </Button>
        </div>

        <div>
          <h2 className="font-bold">
            Für Besitzer eines anderen digitalen Gutscheins (z.B. Spotify,
            Netflix etc.):
          </h2>
          <p>
            Wenn du bereits einen digitalen Gutschein erworben hast, kann dieser
            weiterhin über den Link in deiner Bestätigungsmail abgerufen werden.
          </p>
        </div>

        <p>
          Bei weiteren Fragen zu deinem Gutschein oder anderen Anliegen stehen
          wir dir unter{' '}
          <a
            href="mailto:info@goodee.ch"
            className="hover:underline text-violet-100"
          >
            info@goodee.ch
          </a>{' '}
          zur Verfügung.
        </p>
      </div>

      <div className="container mb-50 mt-16">
        <img src="/img/goodee_bye.jpg" className="w-full" />
      </div>
    </Layout>
  )
}

export default BusinessPage
