import React from 'react'
import cn from 'classnames'

export type LoaderProps = {
  style?: React.CSSProperties
  className?: string
  size?: 'lg' | 'sm'
}

const Loader: React.FC<LoaderProps> = ({
  style,
  className = '',
  size = 'lg',
}) => {
  const small = size === 'sm'
  const dimensions = small ? 'w-7 h-7' : 'w-10 h-10'
  const border = small ? 'border-5' : 'border-8'

  const base = `absolute top-0 left-0 rounded-full ${dimensions} ${border}`
  const position = cn({ relative: !className.match(/absolute|fixed|relative/) })

  return (
    <div className={`${dimensions} ${className} ${position}`} style={style}>
      <div className={`${base} border-current opacity-20`} />
      <div
        className={`${base} border-transparent animate-spin`}
        style={{ borderTopColor: 'currentColor' }}
      />
    </div>
  )
}

export default Loader
